<template>
  <div class="vx-card p-6">
    <base-ag-grid-header
      :rows-selected-count="rowsSelectedCountWithAllSelection"
      :filters-count="filtersAppliedCount"
      :filters-match.sync="filtersMatch"
      @reset-selection="setSelectedRowsNone()"
      @reset-filters="resetFilters()">
      <template v-slot:header-left>
        <items-per-page
          :count="count"
          :currentPage="currentPage"
          :paginationPageSize="paginationPageSize"
          @changed-page-size="paginationSetPageSize">
        </items-per-page>

        <list-action-drop-down
          v-if="isAnyRowsSelected"
          :options="defaultMultipleActionOptions"
          class="ml-3"
          @export="exportData()"
          @delete="confirmDeleteRecords()">
        </list-action-drop-down>
      </template>

      <template v-slot:header-right>
        <vs-button
          radius
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-refresh-cw"
          @click="resetDataSourceAndSelection()">
        </vs-button>
      </template>
    </base-ag-grid-header>

    <vs-alert
      :active.sync="selectionPageAlert"
      class="text-center"
      color="grey-dark"
      closable
      icon-pack="feather"
      close-icon="icon-x">
      {{ $t('$SharedByManyModules.SelectedAllPageInListMsg', {
      count: rowsSelectedCount,
      entity: $tc(`$Entities.${entity}`, rowsSelectedCount) }) }}
      <a
        href="#"
        @click.prevent="setSelectedRowsAll()">
        {{ $t('$SharedByManyModules.SelectAllInListMsg', {
        count: count,
        entity: $tc(`$Entities.${entity}`, count) }) }}
      </a>
    </vs-alert>

    <export-alert-info
      :entity="$tc(`$Entities.${entity}`, 2)"
      :exporting.sync="exportingList"
      :exported.sync="exportedList"
      :fileUrl="urlFileExported"
      :send-email="exportSendEmail"/>

    <ag-grid-vue
      :key="agGridKey"
      ref="agGridTable"
      :domLayout="domLayout"
      :style="gridStyle"
      :components="components"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :column-types="columnTypes"
      :getRowNodeId="getRowNodeId"
      :autoParamsRefresh="true"
      rowSelection="multiple"
      :animateRows="true"
      :suppressRowClickSelection="true"
      rowModelType="infinite"
      :pagination="hasPagination"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="suppressPaginationPanel"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :cacheBlockSize="cacheBlockSize"
      :maxBlocksInCache="maxBlocksInCache"
      @selection-changed="onSelectionChanged"
      @grid-ready="onGridReady"
      @model-updated="onModelUpdate"
      @grid-size-changed="onGridSizeChanged"
      @first-data-rendered="onFirstDataRendered"
      @filter-changed="onFilterChanged"
      @pagination-changed="onPaginationChanged">
    </ag-grid-vue>

    <vs-popup
      :title="exportModalTitle"
      :active.sync="activeModalToExport">
      <transition name="zoom-fade">
        <export-json-to-excel
          v-if="showExportComponent"
          :columns="getColumnsToExport"
          :data="rowsSelected"
          @close="activeModalToExport=false"
          @export="onExport">
        </export-json-to-excel>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import { mapActions } from 'vuex';

// Mixins
import commonListCreateOrEditWithAgGrid from '@/views/modules/_mixins/commonListCreateOrEditWithAgGrid';

// Cell Renderer
import CellRendererActions from '@/views/modules/inbound-sms/InboundSMSListCellRendererActions.vue';
import CellRendererLargeText from '@/views/modules/_components/cell-renderer/CellRendererLargeTextModal.vue';

// Custom Components
import BaseAgGridHeader from '@/views/modules/_components/BaseAgGridHeader.vue';
import ItemsPerPage from '@/views/modules/_components/ItemsPerPage.vue';
import ExportJsonToExcel from '@/views/modules/_components/ExportJsonToExcel.vue';
import ListActionDropDown from '@/views/modules/_components/ListActionDropDown.vue';
import AgGridSelectionHeader from '@/views/modules/_components/AgGridSelectionHeader';
import ExportAlertInfo from '@/views/modules/_components/ExportAlertInfo.vue';

/**
 * Component to list and SMS inbounds
 *
 * @module src/views/modules/inbound-sms/InboundSMSList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {number} actionsMinWidth - min with for action buttons
 * @vue-data {string} [entity='SMSInbound'] - Name of the entity associated to list
 * @vue-data {string} [cellRenderer=false] - to indicate that it isn't cell renderer
 * @vue-data {Array.<Object>} columnDefs - Columns definition of ag grid to list
 * @vue-data {Object} components - Components to use to extend ag grid
 * @vue-data {Object} frameworkComponents - Framework components to extend ag grid
 * @vue-event {void} fetchAgGridData - fetch data to ag grid table
 */
export default {
  name: 'InboundSMSList',
  components: {
    BaseAgGridHeader,
    ExportJsonToExcel,
    ItemsPerPage,
    ListActionDropDown,
    ExportAlertInfo,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererActions,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererLargeText,
  },
  mixins: [
    commonListCreateOrEditWithAgGrid,
  ],
  props: {
    actionsMinWidth: {
      type: Number,
      required: false,
      default: 30,
    },
  },
  data() {
    return {
      entity: this.$enums.Entity.SMS_INBOUND,
      cloneDisabled: true,
      viewDisabled: true,
      editDisabled: true,
      cellRenderer: false,
      exportCollectionFunction: this.exportSmsInbounds,
      deleteRecordFunction: this.removeInboundMessage,
      deleteRecordsFunction: this.removeInboundMessages,
      columnDefs: [
        {
          colId: 'from',
          headerName: this.$t('$General.From'),
          field: 'from',
          filter: true,
          minWidth: 100,
          maxWidth: 820,
          valueFormatter: (params) => (params.value && params.data && params.data.contact
            ? `${params.data.contact.name}(${params.value})`
            : params.value),
          checkboxSelection: true,
          headerComponentParams: { checkboxSelection: true },
        },
        {
          colId: 'to',
          headerName: this.$t('$General.To'),
          field: 'to',
          filter: true,
          minWidth: 100,
          maxWidth: 820,
        },
        {
          colId: 'campaign',
          headerName: this.$tc('$General.Campaign'),
          field: 'campaign',
          filter: false,
          minWidth: 100,
          maxWidth: 820,
          valueFormatter: (params) => (params.value && params.value.name
            ? params.value.name
            : ''),
        },
        {
          colId: 'message',
          headerName: this.$tc('$General.Message'),
          field: 'message',
          filter: true,
          minWidth: 100,
          maxWidth: 1021,
          cellRendererFramework: 'CellRendererLargeText',
        },
      ],
      components: {
        CellRendererActions,
        CellRendererLargeText,
      },
      frameworkComponents: {
        agColumnHeader: AgGridSelectionHeader,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchAllInboundsSms: 'inboundSms/fetchAllInboundsSms',
      exportSmsInbounds: 'inboundSms/exportFile',
      removeInboundMessage: 'inboundSms/removeInboundMessage',
      removeInboundMessages: 'inboundSms/removeInboundMessages',
    }),
    async fetchAgGridData(params) {
      return this.fetchAllInboundsSms({
        ...params,
        populate: [
          { path: 'contact', select: 'id firstName lastName name' },
          { path: 'campaign', select: 'id name' },
        ],
      });
    },
  },
};
</script>
